<template>
  <div class='login'>
    <div class="details">
      <div class="title">
        <h3>中小学直饮水监督管理平台</h3>
      </div>
      <div class="content">
        <div class="loginImg">
          <img src="../../assets/login_bg.png" alt="">
        </div>
        <div class="loginForm">
          <el-tabs v-model="activeName">
            <el-tab-pane label="账密登录" name="first">
              <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="0" class="demo-ruleForm">
                <el-form-item prop="username">
                  <el-input v-model="loginForm.username" placeholder="请输入用户名">
                    <i slot="prefix"><i class="el-icon-user"></i></i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input type="password" v-model="loginForm.password" placeholder="请输入密码">
                    <i slot="prefix"><i class="el-icon-lock"></i></i>
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" :loading="loginLoading" @click="submitForm('loginForm')">登录</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="短信登录" name="second">
              <el-form :model="codeForm" :rules="rules" ref="codeForm" label-width="0" class="demo-ruleForm">
                <el-form-item prop="mobile">
                  <el-input v-model="codeForm.mobile" placeholder="请输入手机号">
                    <i slot="prefix"><i class="el-icon-phone-outline"></i></i>
                    <template slot="append">
                      <el-button @click="checkCode" :disabled="codeDisabled">{{codeDisabled?`${wait_timer}s后重新获取`:'获取验证码'}}</el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="identifyCode">
                  <el-input v-model="codeForm.identifyCode" placeholder="请输入验证码">
                    <i slot="prefix"><i class="el-icon-lock"></i></i>
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" :loading="codeLoading" @click="submitForm('codeForm')">登录</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>

      </div>
      <div class="footer">
        <p>平台服务商：杭州钛比科技有限公司</p>
        <p>联系电话：0571-87755736</p>
        <!-- <div>
          <p>
            主管单位：
            <img src="@/assets/foot1.png" alt="">
            上城区教育局
          </p>
          <p>
            监管单位：
            <img src="@/assets/foot2.png" alt="">
            上城区卫生和计划生育监督所
          </p>
          <p>
            方案支持：
            <img src="@/assets/foot3.png" alt="">
            中国移动
          </p>
          <p>
            技术支持：
            <img src="@/assets/foot4.png" alt="">
            钛比科技
          </p>
        </div>
        <div>
          <p>浙ICP备16003817号-1</p>
          <p>网站标识：3610782</p>
          <p>
            <img src="@/assets/bot.png" alt="">
            浙公网安备33010602009975号
          </p>
        </div>
        <div>
          <p>Operated by Hangzhou terabits technology co. LTD</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { logincode } from '@/api'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback()
      }
    };

    return {
      activeName: 'first',
      loginForm: {
        username: '',
        password: '',
        type: 'user',
        grant_type: 'password'
      },
      codeForm: {
        mobile: '',
        identifyCode: '',
        type: 'phone',
        grant_type: 'password'
      },
      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        username: [
          { validator: this.$validator.username, trigger: 'blur' }
        ],
        mobile: [
          { validator: this.$validator.phone, trigger: 'blur' }
        ],
        identifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入六位验证码', trigger: 'blur' }
        ]
      },
      loginLoading: false,
      codeLoading: false,
      redirect: undefined,
      codeDisabled: false,
      wait_timer: 0
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    // 获取验证码
    checkCode () {
      if (!this.codeForm.mobile) {
        this.$message.error('请输入手机号')
        return false
      }
      if (
        !(/^((0\d{2,3}-\d{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/.test(this.codeForm.mobile))
      ) {
        this.$message.error('请输入正确的手机号')
        return false;
      }
      this.codeDisabled = true;
      this.wait_timer = 60;
      let timer_interval = setInterval(() => {
        this.wait_timer--
        if (this.wait_timer === 0) {
          clearInterval(timer_interval)
          this.codeDisabled = false
        }
      }, 1000);
      logincode({ phone: this.codeForm.mobile }).then(() => {
        // console.log('res :>> ', res);
        this.$message.success('验证码发送成功，请注意查收')
      })
    },
    // 登录
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log('valid :>> ', this[formName]);
          if (formName === 'loginForm') {
            this.loginLoading = true
            this.$store.dispatch("Login", this.loginForm).then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => { });
              this.loginLoading = false;
            }).catch(() => {
              this.loginLoading = false;
            });
          } else {
            this.codeLoading = true
            this.$store.dispatch("Login", this.codeForm).then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => { });
              this.codeLoading = false;
            }).catch(() => {
              this.codeLoading = false;
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100vh;
  background: url("../../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  // background-position-y: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 18;
    margin: auto;
    .title {
      h3 {
        font-size: 42px;
        font-weight: 400;
        color: #415f81;
        // margin-left: 16px;
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      margin: 35px 0;
      box-shadow: -2px 5px 20px #e2e7e7;
      background: #fafcff;
      .loginImg {
        width: 460px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      // .loginForm {
      //   padding: 89px 40px 0;
      //   background: #fff;
      //   // > span {
      //   //   font-size: 27px;
      //   //   font-weight: bold;
      //   //   padding-bottom: 12px;
      //   //   display: inline-block;
      //   //   border-bottom: 5px solid #2176ff;
      //   // }
      //   // .el-form {
      //   //   margin-top: 67px;

      //   //   // padding: 0;
      //   //   .el-form-item {
      //   //     margin-bottom: 40px;
      //   //     width: 100%;
      //   //     .el-input {
      //   //       width: 347px;
      //   //       .el-input__prefix {
      //   //         i {
      //   //           display: flex;
      //   //           align-items: center;
      //   //         }
      //   //       }
      //   //       ::v-deep.el-icon-s-custom,
      //   //       .el-icon-lock {
      //   //         color: rgb(50, 93, 136);
      //   //         font-size: 20px;
      //   //         margin: 0 6px 0 11px;
      //   //       }
      //   //       ::v-deep.el-input__inner {
      //   //         padding-left: 50px;
      //   //         font-size: 19px;
      //   //       }
      //   //     }
      //   //     .el-button {
      //   //       width: 100%;
      //   //       background: rgb(33, 118, 255);
      //   //       border-radius: 3px;
      //   //       border: none;
      //   //       margin-top: 10px;
      //   //       font-size: 24px;
      //   //     }
      //   //   }
      //   // }
      // }
      .loginForm {
        width: 380px;
        height: 320px;
        padding: 20px 40px 40px;
        box-sizing: border-box;
        // background: rgba(0, 0, 0, 0.55);
        // display: flex;
        // align-items: center;
        border-radius: 12px;
        margin: 0 auto;
        ::v-deep .el-tabs {
          margin-top: 30px;
          .el-tabs__nav-wrap {
            margin-bottom: 50px;
            // padding-left: 20px;
            // padding-bottom: 10px;
            .el-tabs__nav {
              padding-bottom: 8px;
            }
            .el-tabs__item {
              font-size: 20px;
              // color: #ffffff;
              padding: 0 30px;
            }
            .is-active {
              color: #415f81;
            }
          }
        }
        ::v-deep .el-form {
          .el-form-item {
            margin-bottom: 30px;
            .el-input {
              .el-input__inner {
                color: #333;
                padding-left: 44px;
              }
              .el-input__prefix {
                left: 12px;
                display: flex;
                align-items: center;
                .el-icon-user,
                .el-icon-lock {
                  font-size: 18px;
                }
              }
              .el-input-group__append {
                background: #0e6ca8;
                color: #ffffff;
                border: none;
                .el-button.is-disabled:hover {
                  background: #0e6ca8;
                  color: #ffffff;
                  border: none;
                }
              }
            }
            .el-form-item__error {
              font-size: 14px;
            }
            .el-button--primary {
              width: 100%;
              border: none;
              color: #ffffff;
            }
          }
          .el-button {
            background: #0e6ca8;
          }
        }
      }
    }
    .footer {
      // position: absolute;
      // right: 0;
      // left: 0;
      // bottom: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      // flex-direction: column;
      p {
        font-size: 14px;
        color: rgb(185, 185, 185);
        font-weight: 500;
      }
      p:first-child {
        margin-right: 30px;
      }
    }
  }
}
// .login {
//   background: url("../../assets/bg.png") no-repeat;
//   background-size: 100% 100%;
//   position: fixed;
//   bottom: 0;
//   top: 0;
//   right: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   h3 {
//     position: absolute;
//     left: 30px;
//     top: 40px;
//   }
//   // .form {

//   // }
//   .form {
//     width: 380px;
//     height: 320px;
//     padding: 20px 40px 40px;
//     box-sizing: border-box;
//     background: rgba(0, 0, 0, 0.55);
//     border-radius: 12px;
//     margin: 0 auto;
//     ::v-deep .el-tabs {
//       .el-tabs__nav-wrap {
//         margin-bottom: 20px;
//         // padding-left: 20px;
//         // padding-bottom: 10px;
//         .el-tabs__nav {
//           padding-bottom: 8px;
//         }
//         .el-tabs__item {
//           font-size: 20px;
//           color: #ffffff;
//           padding: 0 30px;
//         }
//         .is-active {
//           color: #40a9ff;
//         }
//       }
//     }
//     ::v-deep .el-form {
//       .el-form-item {
//         margin-bottom: 30px;
//         .el-input {
//           .el-input__inner {
//             color: #333;
//             padding-left: 44px;
//           }
//           .el-input__prefix {
//             left: 12px;
//             display: flex;
//             align-items: center;
//             .el-icon-user,
//             .el-icon-lock {
//               font-size: 18px;
//             }
//           }
//           .el-input-group__append {
//             background: rgb(24, 144, 255);
//             color: #ffffff;
//             border: none;
//             .el-button.is-disabled:hover {
//               background: rgb(24, 144, 255);
//               color: #ffffff;
//               border: none;
//             }
//           }
//         }
//         .el-form-item__error {
//           font-size: 14px;
//         }
//         .el-button--primary {
//           width: 100%;
//           border: none;
//           color: #ffffff;
//         }
//       }
//     }
//   }
//   .footer {
//     position: absolute;
//     right: 0;
//     left: 0;
//     bottom: 5%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     > div {
//       display: flex;
//       margin-top: 10px;
//       p {
//         display: flex;
//         align-items: center;
//         margin-right: 10px;
//         img {
//           width: 20px;
//           margin: 0 5px;
//         }
//       }
//     }
//   }
// }
</style>
